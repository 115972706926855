import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import createMultiTabState from 'vuex-multi-tab-state';

import auth from './auth'
import location from './location'
import printer from './printer'
import payment from './payment'
import transaction from './transaction'
import settings from './settings'
import terminal from './terminal'
import printerqueue from '../states/printerqueue'

export default createStore({
    plugins: [
        createPersistedState(),
        createMultiTabState({
            statesPaths: [
                'transaction.customerdisplay',
                'auth.authenticated',
            ]
        })
    ],
    modules: {
        auth,
        location,
        printer,
        payment,
        settings,
        transaction,
        terminal,
        printerqueue,
    },
})
