import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import VCalendar from 'v-calendar';
import VueNumberFormat from 'vue-number-format'
import Vue3TouchEvents from "vue3-touch-events";
import Notifications from 'vue3-vt-notifications'

import axios from 'axios'
import App from './App.vue'
import { getMessages, getNumberFormats } from './lib/i18n';
import router from './router'
import { logger } from './lib/logger';
import './main.css'
// import states from './states';
import store from './store'
import Currency from './components/Currency';

let messages = getMessages();
let numberFormats = getNumberFormats();

const i18n = createI18n({
    locale: 'nl',
    messages,
    numberFormats,
})

// axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['auth/token']
axios.defaults.headers.common['Source'] = 'pos'

let app = createApp(App)

// Handle all Vue errors
app.config.errorHandler = (error) => {
    logger.error(error);
    console.error(error)
};



// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
// Sentry.init({
//     app,
//     dsn: "https://6f8c0cf1268f4cfeaaccc65d56d8287b@o399413.ingest.sentry.io/5256476",
//     integrations: [
//       new Integrations.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ['localhost:8080', 'pos.sjef.events', 'pos.sjef.app', 'posv2.sjef.app', 'pos.sjef.dev', /^\//],
//       }),
//     ],
//     tracesSampleRate: 0.25,
//   });

app
.use(i18n)
.use(store)
// .use(states)
.use(Notifications)
.use(router)
.use(VCalendar)
.use(Vue3TouchEvents)
.use(VueNumberFormat, {prefix: '', decimal: '.', thousand: ''})
.component('currency', Currency)
.mount('#app')

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        store.dispatch('auth/logout')
        location.reload();
        router.push('/login');
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});
