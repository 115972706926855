export default {
    namespaced: true,

    state: {
        id: null,
        name: null,
        streetname: null,
        housenumber: null,
        zipcode: null,
        city: null,
        country: null,
        email: null,
        phone_number: null,
        vat_number: null,
    },

    getters: {
        location (state) {
            return {
                id: state.id,
                name: state.name,
                streetname: state.streetname,
                housenumber: state.housenumber,
                zipcode: state.zipcode,
                city: state.city,
                country: state.country,
                email: state.email,
                phone_number: state.phone_number,
                vat_number: state.vat_number,
            }
        },
    },

    mutations: {
        SET_LOCATION(state, location) {
            state.id = location ? location.id : null,
            state.name = location ? location.attributes.name : null,
            state.streetname = location ? location.attributes.streetname : null,
            state.housenumber = location ? location.attributes.housenumber : null,
            state.zipcode = location ? location.attributes.zipcode : null,
            state.city = location ? location.attributes.city : null,
            state.country = location ? location.attributes.country : null,
            state.email = location ? location.attributes.email : null,
            state.phone_number = location ? location.attributes.phone_number : null
            state.vat_number = location ? location.attributes.vat_number : null
        },
    },

    actions: {

        clear({ commit }) {
            commit('SET_LOCATION', null)
        },

        location({ commit }, location) {
            commit('SET_LOCATION', location)
        },
    }
}
