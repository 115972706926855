import axios from 'axios';

export default {
    namespaced: true,

    state: {
        id: null,
        type: 'postransaction',
        prestine: true,
        clerk_id: null,
        clerk_name: null,
        clerk_area_ids: null,
        table_id: null,
        table_name: null,
        table_name_temporary: null,
        table_amount: null,
        table_hourly_rate_item_id: null,
        table_activated_at: null,

        subtotal_id: null,
        subtotal_name: null,

        account_id: null,
        account_name: null,
        booking_id: null,
        booking_number: null,
        customer_id: null,
        customer_name: null,

        in_payment: false,
        payment_id: null,
        paymenttype_id: null,

        lines: [],
        default_items_added: false,
        default_items: [],

        cards: [],
        vouchers: [],
        voucher_payable_amount: 0,
        total: 0,
        items: 0,

        delivery_date: null,
        delivery_time: null,

        shared: '',
    },

    getters: {
        transaction (state) {
            return {
                id: state.id,
                prestine: state.prestine,
                type: state.type,
                cashfunction_id: state.cashfunction_id,
                account_id: state.account_id,
                account_name: state.account_name,
                customer_id: state.customer_id,
                customer_name: state.customer_name,
                clerk_id: state.clerk_id,
                clerk_name: state.clerk_name,
                clerk_area_ids: state.clerk_area_ids,
                table_id: state.table_id,
                table_name: state.table_name,
                table_name_temporary: state.table_name_temporary,
                table_hourly_rate_item_id: state.table_hourly_rate_item_id,
                table_activated_at: state.table_activated_at,
                table_amount: state.table_amount,
                // table_lines: state.table_lines,
                subtotal_id: state.subtotal_id,
                subtotal_name: state.subtotal_name,
                booking_id: state.booking_id,
                booking_number: state.booking_number,
                in_payment: state.in_payment,
                payment_id: state.payment_id,
                paymenttype_id: state.paymenttype_id,
                lines: state.lines,
                cards: state.cards,
                total: state.total,
                items: state.items,
                cashfunction_included: state.lines.filter(line => { return line.type == 'cashinout' }).length > 0}
        },
        id (state) {
            return state.id
        },
        type (state) {
            return state.type
        },
        total (state) {
            return state.total
        },
        lines (state) {
            return state.lines
        },
        customerdisplay (state) {
            return state.customerdisplay
        },
        table (state) {
            return {
                id: state.table_id,
                name: state.table_name,
                name_temporary: state.table_name_temporary,
                activated_at: state.table_activated_at,
                hourly_rate_item_id: state.table_hourly_rate_item_id,
                amount: state.table_amount,
                lines: state.table_lines,
            }
        },
        clerk (state) {
            return {
                id: state.clerk_id,
                name: state.clerk_name,
                area_ids: state.clerk_area_ids,
            }
        }
    },

    mutations: {
        SET_ID(state, id) {
            state.id = id
        },

        SET_TYPE(state, type) {
            state.type = type
        },

        SET_CASHFUNCTION(state, cashfunction) {
            state.cashfunction_id = cashfunction
        },

        SET_ACCOUNT(state, account) {
            state.account_id = account ? account.id : null
            state.account_name = account ? account.attributes.name : null
        },

        SET_CUSTOMER(state, customer) {
            state.customer_id = customer ? customer.id : null
            state.customer_name = customer ? customer.attributes.searchquery : null
        },

        SET_CLERK(state, clerk) {
            state.clerk_id = clerk ? clerk.id : null
            state.clerk_name = clerk ? clerk.attributes.name : null
            state.clerk_area_ids = clerk ? clerk.attributes.area_ids : null
        },

        SET_TABLE(state, table) {
            state.table_id = table ? table.id : null
            state.table_name = table ? table.attributes.name : null
            state.table_name_temporary = table ? table.attributes.name_temporary : null
            state.table_hourly_rate_item_id = table ? table.attributes.hourly_rate_item_id : null
            state.table_amount = table ? table.attributes.total : null
            state.table_lines = table ? table.relationships.lines_consolidated : null
            state.table_activated_at = table ? table.attributes.activated_at : null
        },

        SET_TABLE_DEFAULT(state, table) {
            state.table_id = table ? table.id : null
            state.table_name = table ? table.name : null
        },

        SET_SUBTOTAL(state, subtotal) {
            state.subtotal_id = subtotal ? subtotal.id : null
            state.subtotal_name = subtotal ? subtotal.attributes.name : null
        },

        SET_BOOKING(state, booking) {
            state.booking_id = booking ? booking.id : null
            state.booking_number = booking ? booking.attributes.bookingnumber : null
        },

        SET_PRESTINE(state, prestine) {
            state.prestine = prestine
        },

        SET_LINES(state, lines) {
            state.lines = lines
        },

        SET_CARDS(state, cards) {
            state.cards = cards
        },

        SET_TOTAL(state) {
            let total = 0;
            let items = 0;
            state.lines.forEach(line => {
                total = total + line.total;
                items = items + line.quantity;
            });
            state.total = total
            state.items = items

            // load customerdisplay
            state.customerdisplay = {
                total: state.total,
                items: state.items,
                lines: state.lines
            }
        },

        STORE_DEFAULT_ITEMS (state, items) {
            state.default_items = items
        },

        SET_DEFAULT_ITEMS_ADDED (state, added_state) {
            state.default_items_added = added_state;
        },
    },

    actions: {

        async clear({ commit }) {
            commit('SET_ID', null)
            commit('SET_PRESTINE', true)
            commit('SET_CASHFUNCTION', null)
            commit('SET_TYPE', 'postransaction')
            commit('SET_ACCOUNT', null)
            commit('SET_CUSTOMER', null)
            commit('SET_BOOKING', null)
            commit('SET_TABLE', null)
            // commit('SET_CLERK', null)
            commit('SET_SUBTOTAL', null)
            commit('SET_LINES', [])
            commit('SET_CARDS', [])
            commit('SET_DEFAULT_ITEMS_ADDED', false)
            commit('SET_TOTAL')
        },

        addItem({ dispatch , commit }, data) {
            // get the lines
            let lines = this.state.transaction.lines;

            // line exists?
            let line = lines.find(line => line.id === data.id && line.parent_id === data.parent_id)

            // exists: set quantity and totals
            if (line) {
                line.quantity = line.quantity + data.quantity
                line.total = (line.rate * line.quantity)//.toFixed(2),
            }
            // create new line
            else {
                lines.push({
                    id: data.id,
                    type: data.type ? data.type : 'revenue',
                    parent_id: data.parent_id,
                    item_id: data.item_id,
                    cashfunction_id: data.cashfunction_id,
                    wallettoken_id: data.wallettoken_id,
                    item_parent_id: data.item_parent_id,
                    addon: data.addon,
                    card: data.card,
                    cardnumber: data.cardnumber,
                    editable: data.composed_child ? false : true,
                    composed_child: data.composed_child,
                    quantity: data.quantity,
                    quantity_init: data.quantity,
                    barcode: data.barcode,
                    description: data.description,
                    hourly_rate_item: data.hourly_rate_item ? true : false,
                    kitchen_groceries: data.kitchen_groceries,
                    taxrate: data.taxrate,
                    coin_amount: data.coin_amount ? data.coin_amount : 0,
                    rate: data.rate,
                    rate_without_discount: data.rate,
                    discount_amount: 0,
                    total: (data.rate * data.quantity),//.toFixed(2),
                    variant_id:  data.variant_id,
                    variant_label:  data.variant_label,
                    notes:  data.notes,
                });
            }

            // set the lines
            commit('SET_LINES', lines)
            commit('SET_TOTAL')
            dispatch('updateDefaultItemsFee')
        },

        // todo: remove?
        // removeItem({ commit }, index) {
        //     let lines = this.state.transaction.lines;
        //     lines.splice(index, 1);

        //     commit('SET_LINES', lines)
        //     commit('SET_TOTAL')
        // },

        addQuantity({ dispatch , commit }, line) {
            line.discount_amount = (line.discount_amount / line.quantity) * (line.quantity + 1)
            line.quantity = line.quantity + 1
            line.total = line.quantity * line.rate

            let parent_positions = [];
            let selected_idx = '';
            let next_parent_item_position = 0;
            let selected = false;

            this.state.transaction.lines.forEach(function (transaction_line, idx) {
                if (transaction_line === line) {
                    selected_idx = idx;
                }
                if (!transaction_line.parent_id) {
                    parent_positions.push(idx);
                }
            });

            let position_in_parent_positions = parent_positions.indexOf(selected_idx);

            // set the quantity of the children
            this.state.transaction.lines.forEach(function (child_line, idx) {

                // get the idx of the next parent item
                // the last parent item will not have a next parent item, so simulated large index is required if undefined
                next_parent_item_position = parent_positions[position_in_parent_positions + 1];
                if (next_parent_item_position === undefined) {
                    next_parent_item_position = 10000;
                }

                // check if item is selected or is a child of selected. If so selected = true
                selected = false;
                if (idx > selected_idx && idx < next_parent_item_position) {
                    selected = true;
                }

                // set the quantity of the children
                if (child_line.parent_id != null && line.id == child_line.parent_id || selected === true) {
                    child_line.discount_amount = (child_line.discount_amount / child_line.quantity) * (child_line.quantity + child_line.quantity_init)
                    child_line.quantity = child_line.quantity + child_line.quantity_init
                    child_line.total = child_line.quantity * child_line.rate
                }
            });

            commit('SET_LINES', this.state.transaction.lines)
            commit('SET_TOTAL')
            dispatch('updateDefaultItemsFee')
        },

        // todo: refactor
        subQuantity({ dispatch, commit }, line) {
            line.discount_amount = (line.discount_amount / line.quantity) * (line.quantity - 1)
            line.quantity = line.quantity - 1
            line.total = line.quantity * line.rate

            let parent_positions = [];
            let selected_idx = '';
            let next_parent_item_position = 0;
            let selected = false;

            this.state.transaction.lines.forEach(function (transaction_line, idx) {
                if (transaction_line === line) {
                    selected_idx = idx;
                }
                if (!transaction_line.parent_id) {
                    parent_positions.push(idx);
                }
            });

            let position_in_parent_positions = parent_positions.indexOf(selected_idx);

            // remove line
            if (line.quantity == 0) {
                let lines = this.state.transaction.lines;

                //append all removed idxs to lines_delete. The selected idx is added beforehand,
                // each line after is checked for parent id, as long as there is a parent id the idx is
                let lines_delete = [selected_idx]
                for (let i = selected_idx + 1; i < lines.length; i++) {
                    if (lines[i].parent_id) {
                        lines_delete.push(i);
                    } else {
                        break;
                    }
                }

                // delete lines
                lines_delete.reverse().forEach(function (index) {
                    lines.splice(index, 1)
                });
            } else {
                // set the quantity of the children
                this.state.transaction.lines.forEach(function (child_line, idx) {

                    // get the idx of the next parent item
                    // the last parent item will not have a next parent item, so simulated large index is required if undefined
                    next_parent_item_position = parent_positions[position_in_parent_positions + 1];
                    if (next_parent_item_position === undefined) {
                        next_parent_item_position = 10000;
                    }

                    // check if item is selected or is a child of selected. If so selected = true
                    selected = false;
                    if (idx > selected_idx && idx < next_parent_item_position) {
                        selected = true;
                    }

                    if (child_line.parent_id != null && line.id == child_line.parent_id || selected === true) {
                        child_line.discount_amount = (child_line.discount_amount / child_line.quantity) * (child_line.quantity - child_line.quantity_init)
                        child_line.quantity = child_line.quantity - child_line.quantity_init
                        child_line.total = child_line.quantity * child_line.rate
                    }
                });
            }

            commit('SET_LINES', this.state.transaction.lines)
            commit('SET_TOTAL')
            dispatch('updateDefaultItemsFee')
        },

        async updateDefaultItemsFee({ commit }, addItems = false) {
            let default_items = this.state.transaction.default_items;

            if (!default_items || default_items.length == 0) {
                return;
            }
            let lines = this.state.transaction.lines;
            let default_items_added = this.state.transaction.default_items_added;

            let total = 0;
            lines.forEach(function (line) {
                if (line.defaultItem) {
                    return;
                }

                total += line.total;
            });

            if (this.state.transaction.table_amount) {
                total += parseFloat(this.state.transaction.table_amount);
            }

            default_items.forEach(defaultItem => {
                let item = defaultItem.item.attributes;
                item.id = defaultItem.item.id;
                let line = lines.find(line => line.id === item.id)
                let rate = 0;

                if (defaultItem.type === 'percentage') {
                    rate = total * (parseFloat(defaultItem.amount) / 100);
                } else if (defaultItem.type === 'fixed') {
                    rate = defaultItem.amount;
                } else {
                    if (item.rate >= 0) {
                        rate = item.rate;
                    }
                }

                if (line) {
                    lines.splice(lines.indexOf(line), 1)
                }

                if (rate > 0 && (!default_items_added || line) || addItems) {
                    let line = {
                        id: item.id,
                        defaultItem: true,
                        type: item.type ? item.type : 'revenue',
                        parent_id: null,
                        item_id: item.id,
                        cashfunction_id: item.cashfunction_id,
                        wallettoken_id: item.wallettoken_id,
                        item_parent_id: item.item_parent_id,
                        addon: item.addon,
                        card: item.card,
                        cardnumber: item.cardnumber,
                        editable: item.composed_child ? false : true,
                        composed_child: item.composed_child,
                        quantity: 1,
                        quantity_init: 1,
                        barcode: item.barcode,
                        description: item.description,
                        kitchen_groceries: item.kitchen_groceries,
                        taxrate: item.taxrate,
                        coin_amount: item.coin_amount ? item.coin_amount : 0,
                        rate: rate,
                        rate_without_discount: rate,
                        discount_amount: 0,
                        total: rate,
                        variant_id:  item.variant_id,
                        variant_label:  item.variant_label,
                        notes:  item.notes,
                    }

                    lines.push(line);

                    if (!default_items_added) {
                        commit('SET_DEFAULT_ITEMS_ADDED', true);
                    }
                }
            })

            commit('SET_LINES', this.state.transaction.lines)
            commit('SET_TOTAL')
        },

        storeDefaultItems({ commit }, items){
            commit('STORE_DEFAULT_ITEMS', items)
        },

        setId({ commit }, transaction_id) {
            commit('SET_ID',transaction_id)
        },

        setPrestine({ commit }, prestine) {
            commit('SET_PRESTINE', prestine)
        },

        setType({ commit }, type) {
            commit('SET_TYPE', type)
        },

        setCashfunction({ commit }, cashfunction) {
            commit('SET_CASHFUNCTION', cashfunction)
        },

        setAccount({ commit }, account) {
            commit('SET_ACCOUNT', account)
        },

        setClerk({ commit }, clerk) {
            commit('SET_CLERK', clerk)
        },

        setCustomer({ commit }, customer) {
            commit('SET_CUSTOMER', customer)

            if (customer) {
                commit('SET_PRESTINE', false)
            }
        },

        setTable({ dispatch, commit }, table) {
            commit('SET_TABLE', table)

            if (table) {
                commit('SET_PRESTINE', false)
            }

            if (table.relationships.customer) {
                commit('SET_CUSTOMER', table.relationships.customer)
            }

            dispatch('updateDefaultItemsFee', true)
        },

        setTableDefault({ commit }, table) {
            commit('SET_TABLE_DEFAULT', table)
        },

        setBooking({ commit }, booking) {
            commit('SET_BOOKING', booking)
        },

        setSubtotal({ commit }, subtotal) {
            commit('SET_SUBTOTAL', subtotal)
        },

        lineSelect({ commit }, line) {

            let lines = this.state.transaction.lines;

            lines.forEach(function (line) {
                line.selected = false;
            });

            if (line.editable) {
                line.selected = true;
            }

            commit('SET_LINES', lines);
        },

        multipleLineSelect({ commit }, line) {
            let lines = this.state.transaction.lines;

            if (line.editable) {
                if (line.selected === true) {
                    line.selected = false;
                } else {
                    line.selected = true;
                }
            }

            commit('SET_LINES', lines);
        },

        resetAllDiscount({ commit }) {
            let discount = 0;

            // todo: remove children
            let lines = this.state.transaction.lines;

            lines.forEach(function (line) {
                line.selected = false;
                // line.quantity = line.quantity * -1;
                line.rate = line.rate_without_discount * (1 - (discount / 100))
                line.discount_amount = line.quantity * (line.rate_without_discount * (discount / 100))
                line.total = line.quantity * line.rate
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        unselectAllLines({ commit }) {
            let lines = this.state.transaction.lines;

            lines.forEach(function (line) {
                line.selected = false;
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        setLines({ commit }, lines) {
            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        selectAllLines({ commit }) {
            let lines = this.state.transaction.lines;

            lines.forEach(function (line) {
                line.selected = true;
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        lineDelete({ commit }, line) {
            let lines_delete = [];
            let lines = this.state.transaction.lines;

            lines.forEach(function(ln, index) {
                if (line.id === ln.id || line.id === ln.parent_id) {
                    lines_delete.push(index);
                }
            });

            // delete lines
            lines_delete.reverse().forEach(function (index) {
                lines.splice(index, 1)
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        lineSelectedDelete({ commit }) {
            // todo: remove children
            let lines = this.state.transaction.lines
            let line_selected = null
            let lines_delete = []

            // get selected line
            lines.forEach(function (line, index) {
                if (line.selected == true && line.editable && line.type !== 'cashinout') {
                    line_selected = line
                    lines_delete.push(index)

                    for (let i = index + 1; i < lines.length; i++) {
                        if (lines[i].parent_id) {
                            lines_delete.push(i);
                        } else {
                            break;
                        }
                    }
                }
            });

            if (!line_selected) {
                return
            }

            // delete lines
            lines_delete.reverse().forEach(function (index) {
                lines.splice(index, 1)
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        lineSelectedDiscountPercentage({ commit }, discount) {

            // todo: remove children
            let lines = this.state.transaction.lines;

            lines.forEach(function (line) {

                if (line.selected == true && line.editable && line.type !== 'cashinout') {
                    // line.quantity = line.quantity * -1;
                    line.rate = line.rate_without_discount * (1 - (discount / 100))
                    line.discount_amount = line.quantity * (line.rate_without_discount * (discount / 100))
                    line.total = line.quantity * line.rate
                }
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        lineSelectedDiscountFixed({ commit }, discount) {

            // todo: remove children
            let lines = this.state.transaction.lines;

            lines.forEach(function (line) {
                if (line.selected == true && line.editable && line.type !== 'cashinout') {
                    line.total = line.quantity * line.rate_without_discount

                    if (discount <= line.total) {
                        line.total -= discount
                    }
                }
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        lineSelectedRetour({ commit }) {
            // todo: remove children
            let lines = this.state.transaction.lines;
            let parent_positions = [];
            let position_in_parent_positions = 0;
            let selected_idx = '';
            let next_parent_item_position = 0;
            let selected = false;

            // There might be child products of child product which will not be retoured, because their direct parent the retoured product
            // In this loop we get all the positions of items with the same parent, including the parent.
            parent_positions = [];
            lines.forEach(function (line, idx) {
                if (!line.parent_id) {
                    parent_positions.push(idx);
                }
            });

            // In this loop we retour the selected line including it's children.
            lines.forEach(function (line, idx) {

                // to check which of the items are children we need to know the index of the following parent item.
                // all in between are children
                if (line.selected === true) {
                    position_in_parent_positions = parent_positions.indexOf(idx);
                    selected_idx = idx;
                }

                // get the idx of the next parent item
                // the last parent item will not have a next parent item, so simulated large index is required if undefined
                next_parent_item_position =  parent_positions[position_in_parent_positions + 1];
                if (next_parent_item_position === undefined) {
                    next_parent_item_position = 10000;
                }

                // check if item is selected or is a child of selected. If so selected = true
                selected = false;
                if (selected_idx !== '') {
                    if (idx >= selected_idx && idx < next_parent_item_position) {
                        selected = true;
                    }
                }

                // if ((line.selected === true && line.type !== 'cashinout' && line.editable) || selected === true) {

                // REMOVED CASHINOUT FROM CONDITIONS

                    // retour the current item, or the child item of the current item
                if ((line.selected === true && line.editable) || selected === true) {
                    line.quantity = line.quantity * -1;
                    line.total = line.quantity * line.rate
                }
            });

            commit('SET_LINES', lines);
            commit('SET_TOTAL')
        },

        // addCard({ commit }, data) {
            // console.log(data)
            // // get the cards
            // let cards = this.state.transaction.cards;

            // // line exists?
            // let card = cards.find(card => card.cardnumber === data.cardnumberd)

            // // exists: set quantity and totals
            // if (card) {
            //     return
            // }

            // // create new card
            // cards.push({
            //     cardnumber: data.cardnumber,
            //     type: data.type,
            // });

            // // set the cards
            // commit('SET_CARDS', cards)
        // },
    },
}
