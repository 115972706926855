import { Logtail } from '@logtail/browser';
// import * as store from '../store';

// const logtail = new Logtail("3TGbuqQNis2FnSh8DJJvmBoa");

const logger = {
    error: (message) => {
        console.log(message);
        // logtail.error(message);
    },
    info: (message) => {
        console.log(message);
        // logtail.info(message);
    },
};

export {
    logger
}
