import states from "../states";

const set = (key, variable) => {
    states.dispatch(key, JSON.stringify(variable));
}

const get = (key) => {
    // console.log(key, states.getters[key])
    return JSON.parse(states.getters[key])
}

const setField = (key_get, key_set, field, variable) => {
    let get_result = get(key_get);

    get_result[field] = variable;
    set(key_set, get_result);
}


const wipeStates = () => {
    // set('payment/setPayment', {});
    set('token/setToken', false);
    set('config/setConfig', {});
    set('user/setUser', {});
}

export {
    get,
    set,
    setField,
    wipeStates,
}
