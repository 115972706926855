import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import printerqueue from './printerqueue';

export default createStore({
    plugins: [createPersistedState()],
    modules: {
        printerqueue,
    },
})
