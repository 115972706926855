// import axios from 'axios'

export default {
    namespaced: true,

    state: {
        cashfunction_balance_customer_id: null,
        itemscanner: true,
        wallet_maximum_credit_amount: null,
    },

    getters: {
        settings (state) {
            return {
                cashfunction_balance_customer_id: state.cashfunction_balance_customer_id,
                wallet_maximum_credit_amount: state.wallet_maximum_credit_amount,
            }
        },
        cashfunctionBalanceCustomerId (state) {
            return state.cashfunction_balance_customer_id
        },
        walletMaximumCreditAmount (state) {
            return state.wallet_maximum_credit_amount
        },
        itemscanner (state) {
            return state.itemscanner
        },
    },

    mutations: {
        SET_SETTINGS(state, settings) {
            state.cashfunction_balance_customer_id = settings ? settings.attributes.cashfunction_balance_customer_id : null
            state.wallet_maximum_credit_amount = settings ? settings.attributes.wallet_maximum_credit_amount : 250
        },

        SET_ITEMSCANNER(state, itemscanner) {
            state.itemscanner = itemscanner
        },
    },

    actions: {

        clear({ commit }) {
            commit('SET_SETTINGS', null)
            commit('SET_ITEMSCANNER', true)
        },

        settings({ commit }, settings) {
            commit('SET_SETTINGS', settings)
        },

        itemscanner({ commit }, itemscanner) {
            commit('SET_ITEMSCANNER', itemscanner)
        },
    }
}
