// import axios from 'axios'
import { logger } from '../lib/logger';


export default {
    namespaced: true,

    state: {
        id: null,
        code: null,
        admin_mode: false,
        modal_active: false,
        autocancel: null,
        printer_mode: 'webprint',
        client_name: null,
        printer_error: false,
        layout: null,
        mobile_layout: null,
        buttonsize: 'medium',
        product_columns: 'columns-auto',
        transaction_area_width: 'w-1/5',
        clerks_timeregistrations: null,
        extra_basket_functionalities_position: 'in-transaction-area',
        colorscheme: 'dark',
        timeslots: null,
        table_required: null,
        clerk_required: null,
        clerk_logout: null,
        table_forceclose_required: null,
        last_transaction_id: null,
        transaction_previous: null,
        titles: null,
        searchbar_active: null,
        inverted: null,
        currency: 'eur',
    },

    getters: {
        terminal (state) {
            return {
                id: state.id,
                code: state.code,
                admin_mode: true, //state.admin_mode,
                modal_active: false, //state.modal_state,

                startpage: state.startpage,

                client_name: state.client_name,

                printer_mode: state.printer_mode,
                printer_error: state.printer_error,
                layout: state.layout,
                mobile_layout: state.mobile_layout,
                mode: state.mode,
                role: state.role,
                pin_terminal_id: state.pin_terminal_id,
                pincode: state.pincode,
                pincode_admin: state.pincode_admin,
                items_mode: state.items_mode,
                items_view: state.items_view,
                transactionscreen: state.transactionscreen,
                titles: state.titles,
                buttonsize: state.buttonsize,
                product_columns: state.product_columns,
                clerks_timeregistrations: state.clerks_timeregistrations,
                transaction_area_width: state.transaction_area_width,
                extra_basket_functionalities_position: state.extra_basket_functionalities_position,
                colorscheme: state.colorscheme,
                searchbar_active: state.searchbar_active,
                inverted: state.inverted,
                map_factor: state.map_factor,

                // receipts
                receipt_type: state.receipt_type,
                receipt_print: state.receipt_print,
                receipt_reference: state.receipt_reference,
                receipt_consolidate: state.receipt_consolidate,
                receipt_print_eft: state.receipt_print_eft,

                // table settings
                table_required: state.table_required,
                clerk_required: state.clerk_required,
                clerk_logout: state.clerk_logout,
                table_forceclose_required: state.table_forceclose_required,
                default_area_id: state.default_area_id,
                default_table_id: state.default_table_id,
                default_table_name: state.default_table_name,

                subtotal_required: state.subtotal_required,
                wallettoken_create_unknown: state.wallettoken_create_unknown,
                wallettoken_connect_new_wallet: state.wallettoken_connect_new_wallet,
                wallettoken_default_scan: state.wallettoken_default_scan,

                last_transaction_id: state.last_transaction_id,

                customerdisplay_image_url: state.customerdisplay_image_url,

                // currency

                currency: state.currency,
            }
        },

        layout(state) {
            return state.layout;
        },

        adminMode () {
        // adminMode (state) {
            return true //state.admin_mode
        },

        modalActive (state) {
            return state.modal_active
        },

        isMobile(state) {
            return ['mobile', 'mobileordering'].includes(state.layout)
        },

        transactionPrevious (state) {
            return state.transaction_previous
        },

        printerError (state) {
            return state.printer_error
        },
    },

    mutations: {
        SET_TERMINAL(state, terminal) {
            state.id = terminal ? terminal.id : null
            state.code = terminal ? terminal.attributes.code : null
            state.type = terminal ? terminal.attributes.type : null

            state.startpage = terminal ? terminal.attributes.startpage : null
            state.printer_mode = terminal ? terminal.attributes.printer_mode : null
            state.client_name = terminal ? terminal.attributes.client_name : null
            // state.printer_error = terminal ? terminal.attributes.printer_error : false

            let layout = terminal ? terminal.attributes.layout : null
            let mobile_layout = null

            // The `mobileordering_buttons` should be treated as `mobileordering` layout
            // to avoid breaking the layout of the mobile ordering buttons.
            if (layout === 'mobileordering_buttons') {
                layout = 'mobileordering'
                mobile_layout = 'mobileordering_buttons'
            }

            state.layout = layout
            state.mobile_layout = mobile_layout
            state.transactionscreen = terminal ? terminal.attributes.transactionscreen : null
            state.mode = terminal ? terminal.attributes.mode : null
            state.role = terminal ? terminal.attributes.role : null
            state.pin_terminal_id = terminal ? terminal.attributes.pin_terminal_id : null
            state.pincode = terminal ? terminal.attributes.pincode : null
            state.pincode_admin = terminal ? terminal.attributes.pincode_admin : null
            state.items_mode = terminal ? terminal.attributes.items_mode : null
            state.items_view = terminal ? terminal.attributes.items_view : 'overview'
            state.buttonsize = terminal ? terminal.attributes.buttonsize : 'medium'
            state.product_columns = terminal ? terminal.attributes.product_columns : 'columns-auto'
            state.clerks_timeregistrations = terminal ? terminal.attributes.clerks_timeregistrations : false
            state.transaction_area_width = terminal ? terminal.attributes.transaction_area_width : 'w-1/5'
            state.extra_basket_functionalities_position = terminal ? terminal.attributes.extra_basket_functionalities_position : 'w-1/5'
            // state.pro = terminal ? terminal.attributes.pro : 'medium'
            state.colorscheme = terminal ? terminal.attributes.colorscheme : 'dark'
            state.searchbar_active = terminal ? terminal.attributes.searchbar_active : false
            state.inverted = terminal ? terminal.attributes.inverted : false
            state.titles = terminal ? terminal.attributes.titles : 'top'
            state.map_factor = terminal ? terminal.attributes.map_factor : 1

            state.receipt_type = terminal ? terminal.attributes.receipt_type : 'default'
            state.receipt_print = terminal ? terminal.attributes.receipt_print : false
            state.receipt_reference = terminal ? terminal.attributes.receipt_reference : 'transactionnumber'
            state.receipt_consolidate = terminal ? terminal.attributes.receipt_consolidate : false
            state.receipt_print_eft = terminal ? terminal.attributes.receipt_print_eft : false

            state.default_area_id = terminal ? terminal.attributes.default_area_id : null
            state.default_table_id = terminal ? terminal.attributes.default_table_id : null
            state.table_required = terminal ? terminal.attributes.table_required : false
            state.clerk_required = terminal ? terminal.attributes.clerk_required : true
            state.clerk_logout = terminal ? terminal.attributes.clerk_logout : true
            state.table_forceclose_required = terminal ? terminal.attributes.table_forceclose_required : false
            state.subtotal_required = terminal ? terminal.attributes.subtotal_required : false
            state.wallettoken_create_unknown = terminal ? terminal.attributes.wallettoken_create_unknown : false
            state.wallettoken_connect_new_wallet = terminal ? terminal.attributes.wallettoken_connect_new_wallet : false
            state.wallettoken_default_scan = terminal ? terminal.attributes.wallettoken_default_scan : 'cardnumber'


            state.customerdisplay_image_url = terminal ? terminal.attributes.customerdisplay_image_url : null
            state.currency = terminal ? terminal.attributes.currency : 'eur'
        },

        SET_ADMIN_MODE(state, admin_mode) {
            state.admin_mode = admin_mode
        },

        SET_MODAL_ACTIVE(state, modal_active) {
            state.modal_active = modal_active
        },

        SET_LAST_TRANSACTION_ID(state, transaction_id) {
            state.last_transaction_id = transaction_id

        },
        SET_TRANSACTION_PREVIOUS(state, transaction) {
            state.transaction_previous = transaction
        },

        SET_PRINTER_ERROR(state, printer_error) {
            state.printer_error = printer_error
        },
    },

    actions: {

        clear({ commit }) {
            commit('SET_TERMINAL', null)
            commit('SET_ADMIN_MODE', false)
            commit('SET_MODAL_ACTIVE', false)
            commit('SET_LAST_TRANSACTION_ID', null)
            commit('SET_TRANSACTION_PREVIOUS', null)
        },

        terminal({ commit }, terminal) {
            commit('SET_TERMINAL', terminal)
        },

        setAdminMode({ commit }, mode) {
            commit('SET_ADMIN_MODE', mode)
        },

        setModalActive({ commit }, state) {
            commit('SET_MODAL_ACTIVE', state)
        },

        setLastTransactionId({ commit }, transaction_id) {
            commit('SET_LAST_TRANSACTION_ID', transaction_id)
        },

        setTransactionPrevious({ commit }, transaction) {
            commit('SET_TRANSACTION_PREVIOUS', transaction)
        },

        setPrinterError({ commit }, printer_error) {
            logger.info(printer_error)
            commit('SET_PRINTER_ERROR', printer_error)
        },
    }
}
