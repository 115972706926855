import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
// import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/customerdisplay',
        name: 'pos.customerdisplay',
        component: () => import('../views/pos/display/CustomerDisplay.vue'),
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                name: 'landing',
                beforeEnter(to, from, next) {
                    if (store.getters['terminal/terminal'].layout == 'mobileordering') {
                        if (store.getters['terminal/terminal'].clerk_required == true && !store.getters['transaction/transaction'].clerk_id) {
                            next({ name: 'clerk.login' });
                            return;
                        }
                        next({ name: 'mobilepos.areas' });
                    }
                    else if(store.getters['terminal/terminal'].layout == 'mobiledirect') {
                        next({ name: 'mobilepos.direct' })
                    }
                    else if(store.getters['terminal/terminal'].layout == 'mobilepinonly') {
                        next({ name: 'mobilepos.pinonly' })
                    }
                    else {
                        next({ name: 'pos' })
                    }
                },
            },

            // clerk login
            {
                path: '/clerk/login',
                name: 'clerk.login',
                component: () => import('../views/clerk/Login.vue'),
            },

            // pos routes
            {
                path: '/pos',
                name: 'pos',
                component: () => import('../views/pos/Pos.vue'),
                beforeEnter(to, from, next) {
                    // todo: move to Pos.vue
                    if (store.getters['transaction/transaction'].id) {
                        next({ name: 'transaction' });
                    }
                    else if (store.getters['terminal/terminal'].startpage == 'clerk.login' && !store.getters['transaction/transaction'].clerk_id) {
                        next({ name: 'clerk.login' });
                    }
                    else if (store.getters['terminal/terminal'].startpage == 'tables' && store.getters['transaction/transaction'].prestine) {
                        next({ name: 'pos.tables' });
                    } else {
                        next()
                    }
                },
            },
            {
                path: '/pos/customers',
                name: 'pos.customers',
                component: () => import('../views/pos/customer/Customers.vue'),
            },
            {
                path: '/pos/tables',
                name: 'pos.tables',
                component: () => import('../views/pos/table/Tables.vue'),
            },
            {
                path: '/tables/virtual',
                name: 'pos.tables.virtual',
                props: true,
                component: () => import('../views/pos/table/TablesVirtual.vue'),
            },
            {
                // path: '/tables/move/:area_id/:table_id',
                path: '/tables/move/:area_id/',
                name: 'pos.tables.move',
                props: true,
                component: () => import('../views/pos/table/TablesMove.vue'),
            },


            // mobilepos routes
            {
                path: '/mobilepos',
                name: 'mobilepos',
                component: () => import('../views/mobilepos/MobilePos.vue'),
                beforeEnter(to, from, next) {

                    axios.get('/terminal').then(response => {
                        console.log(response)
                        store.dispatch('terminal/terminal', response.data.data)
                        store.dispatch('location/location', response.data.data.relationships.location)
                        store.dispatch('printer/printer', response.data.data.relationships.printer)
                        if (store.getters['transaction/transaction'].id) {
                            next({ name: 'transaction' })
                        } else if (store.getters['terminal/terminal'].layout == 'mobiledirect') {
                            next({ name: 'mobilepos.direct' })
                        } else if (store.getters['terminal/terminal'].clerk_required) {
                            next({ name: 'clerk.login' })
                        } else if(store.getters['terminal/terminal'].layout == 'mobilepinonly') {
                            next({ name: 'mobilepos.pinonly' })
                        } else {
                            next({ name: 'mobilepos.areas' })
                        }
                    }).catch(error => {
                        console.error(error)
                    })

                },
            },

            {
                path: '/mobilepos/direct',
                name: 'mobilepos.direct',
                component: () => import('../views/mobilepos/pages/Direct.vue'),
            },

            {
                path: '/mobilepos/receipt',
                name: 'mobilepos.receipt',
                component: () => import('../views/mobilepos/pages/Receipt.vue'),
            },

            {
                path: '/mobilepos/pinonly',
                name: 'mobilepos.pinonly',
                component: () => import('../views/mobilepos/pages/Pinonly.vue'),
            },

            // {
            //     path: '/mobilepos/clerk',
            //     name: 'mobilepos.clerk',
            //     component: () => import('../views/mobilepos/pages/Clerk.vue'),
            // },

            {
                path: '/mobilepos/areas',
                name: 'mobilepos.areas',
                component: () => import('../views/mobilepos/pages/Areas.vue'),
            },

            {
                path: '/mobilepos/recent-transactions',
                name: 'mobilepos.recent-transactions',
                component: () => import('../views/mobilepos/pages/RecentTransactions.vue'),
            },

            {
                path: '/mobilepos/areas/:area_id/tables',
                props: true,
                name: 'mobilepos.tables',
                component: () => import('../views/mobilepos/pages/Tables.vue'),
            },

            {
                path: '/mobilepos/areas/:area_id/tables/:table_id',
                props: true,
                name: 'mobilepos.table',
                component: () => import('../views/mobilepos/pages/TableView.vue'),
            },

            // global routes
            {
                path: '/transaction',
                props: {
                    paymenttype_id: false,
                },
                name: 'transaction',
                component: () => import('../views/transaction/Transaction.vue'),
            },
            {
                path: '/payment',
                name: 'transaction.payment',
                component: () => import('../views/transaction/Payment.vue'),
            },
        ]
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/authenticated']) {
            next()
            return
        }
        next('/login')
    } else {
        if (store.getters['auth/authenticated'] && to.name == 'login') {
            next('/')
        }
        next()
    }
})

export default router
