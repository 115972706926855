// import axios from 'axios'

export default {
    namespaced: true,
    state: {
        id: null,
        paymenttype_id: null,
        mutation_id: null,
        amount: null,
        in_payment: false,
        async: false,
    },

    getters: {
        payment (state) {
            return {
                id: state.id,
                paymenttype_id: state.paymenttype_id,
                mutation_id: state.mutation_id,
                in_payment: state.in_payment,
                amount: state.amount,
                async: state.async,
            }
        },
        mutation_id(state) {
            return state.mutation_id
        }
    },

    mutations: {
        SET_ID(state, id) {
            state.id = id
        },
        SET_PAYMENTTYPE_ID(state, paymenttype_id) {
            state.paymenttype_id = paymenttype_id
        },
        SET_MUTATION_ID(state, mutation_id) {
            state.mutation_id = mutation_id
        },
        SET_AMOUNT(state, amount) {
            state.amount = amount
        },
        SET_IN_PAYMENT(state, in_payment) {
            state.in_payment = in_payment
        },
        SET_ASYNC(state, async) {
            state.async = async
        },
    },

    actions: {

        clear({ commit }) {
            commit('SET_ID', null)
            commit('SET_PAYMENTTYPE_ID', null)
            commit('SET_MUTATION_ID', null)
            commit('SET_AMOUNT', null)
            commit('SET_IN_PAYMENT', false)
            commit('SET_ASYNC', false)
        },

        setId({ commit }, transaction_id) {
            commit('SET_ID', transaction_id)
        },

        setPaymenttypeId({ commit }, paymenttype_id) {
            commit('SET_PAYMENTTYPE_ID', paymenttype_id)
        },

        setMutationId({ commit }, mutation_id) {
            commit('SET_MUTATION_ID', mutation_id)
        },

        setAmount({ commit }, amount) {
            commit('SET_AMOUNT', amount)
        },

        setInPayment({ commit }, in_payment) {
            commit('SET_IN_PAYMENT', in_payment)
        },

        setAsync({ commit }, async) {
            commit('SET_ASYNC', async)
        },
    }
}
