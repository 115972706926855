export default {
    namespaced: true,
    state: {
        queue: JSON.stringify([]),
    },

    getters: {
        getQueue: (state) => state.queue,
    },

    mutations: {
        QUEUE(state, queue) {
            state.queue = queue;
        },
    },

    actions: {
        setQueue({ commit }, queue){
            commit('QUEUE', queue);
        },
    }
}
