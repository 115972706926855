export const getMessages = () => {

    let locales = { en: {}, nl: {}};

    locales['nl'] = require('../locales/nl-NL.json');
    locales['en'] = require('../locales/en-US.json');

    return locales;
}

export const getNumberFormats = () => {

    return {
        'nl': {
            currency: {
                style: 'currency', currency: 'eur', notation: 'standard'
            },
            decimal: {
                style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
            },
            percent: {
                style: 'percent', useGrouping: false
            }
        }
    }
}
