<template>
    <notificationGroup group="notifications">
        <div class="fixed z-50 inset-0 flex px-4 py-6 pointer-events-none p-6 items-end justify-center">
            <div class="max-w-sm w-full">
                <notification v-slot:default="{notifications}">
                    <div class="w-full mx-auto bg-white shadow-md rounded-sm overflow-hidden mt-1 border-2"  :class="setBorderClass(notification)"
                        v-for="notification in notifications"
                        :key="notification.id">
                        <div class="flex justify-center items-center w-6"></div>

                        <div class="py-2 px-4 text-center">
                            <div class="mx-3">
                                <span class="font-semibold" :class="setTextClass(notification)">{{notification.title}}</span>
                                <p class="text-gray-600 text-sm">{{notification.text}}</p>
                            </div>
                        </div>
                    </div>
                </notification>
            </div>
        </div>
    </notificationGroup>
</template>

<script>

export default {
    data() {
        return {}
    },
    methods: {
        setBorderClass(notification) {
            if (notification.type == 'success') {
                return 'border-green-500'
            }
            else if (notification.type == 'error') {
                return 'border-red-500'
            }
            else if (notification.type == 'warning') {
                return 'border-yellow-300'
            }
            else if (notification.type == 'info') {
                return 'border-blue-500'
            }
        },
        setTextClass(notification) {
            if (notification.type == 'success') {
                return 'text-green-500'
            }
            else if (notification.type == 'error') {
                return 'text-red-500'
            }
            else if (notification.type == 'warning') {
                return 'text-yellow-300'
            }
            else if (notification.type == 'info') {
                return 'text-blue-500'
            }
        }
    }
}
</script>
