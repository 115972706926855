<template>
    <div v-if="error" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="modal-centering inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">
                    <div class="flex justify-between items-center mb-5">
                        <div class="sm:mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                {{$t('translations.components.printer.error')}}
                            </h3>
                        </div>
                    </div>
                    <div>{{ error }}</div>
                </div>
                <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                    <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto mr-4">
                        <a @click="reset()"
                           class="button button-filled button-large">
                           {{$t('translations.components.printer.try-again')}}
                        </a>
                    </span>
                    <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto mr-4">
                        <a @click="clear()"
                           class="button button-danger button-large">
                           {{$t('translations.components.printer.delete-printjobs')}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import * as _printerqueue from '../lib/printerqueue.js';
import { logger } from '../lib/logger';



export default {
    name: 'Printer',
    data () {
        return {
            isPrinting: false,
        };
    },
    mounted () {
        this.reset();
    },
    methods: {

        reset () {
            this.isPrinting = false;
            this.$store.dispatch('terminal/setPrinterError', false);
            this.handleQueue();
        },

        clear () {
            this.isPrinting = false;
            this.$store.dispatch('terminal/setPrinterError', false);
            _printerqueue.clear();
        },

        handleQueue () {
            console.log('handleQueue');
            if (this.printer === false) {
                _printerqueue.remove();
                console.log('no printer found');
                return;
            }

            // get the first printjob from queue
            const printjob = this.queue[0];

            if (!printjob) {
                return;
            }
            this.isPrinting = true;

            let self = this;

            this.printer.onReceive = function (response) {

                if (self.printer.isCoverOpen({ traderStatus: response.traderStatus })) {
                    self.$store.dispatch('terminal/setPrinterError', 'Printerdeksel is open. Sluit deksel van de printer');
                    // this.$notify({ group: "notifications", type: 'error', title: "Printerdeksel is open", text: "Sluit de deksel van de printer" }, 2000);
                    return;
                }
                if (self.printer.isHighTemperatureStop({ traderStatus: response.traderStatus })) {
                    self.$store.dispatch('terminal/setPrinterError', 'Printer is oververhit. Wacht tot de printer is afgekoeld');
                    // this.$notify({ group: "notifications", type: 'error', title: "Printerdeksel is oververhit", text: "Wacht tot de printer is afgekoeld" }, 2000);
                    return;
                }

                if (self.printer.isNonRecoverableError({ traderStatus: response.traderStatus })) {
                    self.$store.dispatch('terminal/setPrinterError', 'Printer geeft een onherstelbare Error');
                    // this.$notify({ group: "notifications", type: 'error', title: "Printer Error", text: "Printer geeft een onherstelbare Error" }, 2000);
                    return;
                }

                if (self.printer.isAutoCutterError({ traderStatus: response.traderStatus })) {
                    self.$store.dispatch('terminal/setPrinterError', 'De automatische snijfunctie van de printer werkt niet');
                    // this.$notify({ group: "notifications", type: 'error', title: "Printer Cutter", text: "De automatische snijfunctie van de printer werkt niet" }, 2000);
                    return;
                }

                if (self.printer.isBlackMarkError({ traderStatus: response.traderStatus })) {
                    self.$store.dispatch('terminal/setPrinterError', 'Blackmark Error');
                    // this.$notify({ group: "notifications", type: 'error', title: "Blackmark Error", text: "Blackmark Error" }, 2000);

                    return;
                }

                if (self.printer.isPaperEnd({ traderStatus: response.traderStatus })) {
                    self.$store.dispatch('terminal/setPrinterError', 'Het papier van de printer is op');
                    // this.$notify({ group: "notifications", type: 'error', title: "Papier is op", text: "Het papier van de printer is op" }, 2000);
                    return;
                }

                // if (self.printer.isPaperNearEnd({ traderStatus: response.traderStatus })) {


                    // self.$store.dispatch('terminal/setPrinterError', 'Het papier van de printer is bijna op');
                    // return;

                //     // self.$store.dispatch('terminal/setPrinterError', 'Het papier van de printer is bijna op');
                //     // this.$notify({ group: "notifications", type: 'error', title: "Papier is bijna op", text: "Het papier van de printer is bijna op" }, 2000);
                //     return;

                // }

                if (self.printer.isOffLine({ traderStatus: response.traderStatus })) {
                    self.$store.dispatch('terminal/setPrinterError', 'Printer is offline. Controleer de netwerkverbinding');
                    return;
                }

                if (response.traderSuccess) {
                    _printerqueue.remove();

                    if (self.queue.length > 0) {
                        self.handleQueue();
                    } else {
                        self.isPrinting = false;
                    }
                }
                return 'success';
            };

            this.printer.onError = function (error) {
                // console.error(error);
                logger.error(error);
                self.$store.dispatch('terminal/setPrinterError', 'Een onbekende fout opgetreden tijdens het printen');
                return;
            };

            this.printer.sendMessage({
                request: printjob,
            });

        },
    },

    computed: {
        queue () {
            return _printerqueue.get();
        },

        printer () {
            if (!this.$store.getters['printer/ipaddress']) {
                return false;
            }
            // eslint-disable-next-line no-undef
            return new StarWebPrintTrader({
                url: this.$store.getters['printer/protocol'] + '://' + this.$store.getters['printer/ipaddress'] + '/StarWebPRNT/SendMessage',
            });
        },

        terminal () {
            return this.$store.getters['terminal/terminal'];
        },

        transaction () {
            return this.$store.getters['transaction/transaction'];
        },

        error () {
            return this.$store.getters['terminal/printerError'];
        },
    },
    watch: {
        queue: function (queue) {
            if (queue.length > 0 && this.isPrinting == false) {
                this.handleQueue();
            }
        },
    },
};
</script>
