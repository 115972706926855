<template>
	<div class="h-full">
		<div class="wrapper h-full">
			<div class="container-fluid h-full">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
