export default {
    namespaced: true,
    state: {
        queue: [],
        ipaddress: null,
        protocol: null,
        paperwidth: null,
        paperempty: 0,
    },
    getters: {
        queue: state => state.queue,
        protocol: state => state.protocol,
        ipaddress: state => state.ipaddress,
        paperwidth: state => state.paperwidth,
        autocutter: state => state.autocutter,
        paperempty: state => state.paperempty,
        printjob: state => state.queue[0],
    },
    mutations: {
        setPrinter(state, printer) {
            state.protocol = printer ? printer.attributes.protocol : null;
            state.ipaddress = printer ? printer.attributes.ipaddress : null;
            state.paperwidth = printer ? printer.attributes.paperwidth : null;
            state.autocutter = printer ? printer.attributes.autocutter : null;
        },

        setPaperempty(state, paperempty) {
            state.paperempty = paperempty;
        },

        addPrintjob(state, job) {
            state.queue.push(job);
        },

        removePrintJob(state) {
            state.queue.shift();
        },
    },
    actions: {
        printer({ commit }, printer) {
            commit('setPrinter', printer);
        },
        setPaperempty({ commit }, status) {
            commit('setPaperempty', status);
        },

        newPrintjob({ commit }, job) {
            commit('addPrintjob', job);
        },
        removePrintjob({ commit }) {
            commit('removePrintJob');
        },
        printingStart({ commit }) {
            commit('busy', true);
        },
        printingEnd({ commit }) {
            commit('busy', false);
        },

        clear({ commit }) {
            commit('setPrinter', null);
        }
    }
}
