import { logger } from './logger';
import * as _state from './state';


const get = () => {
    logger.info('printjob:get')

    return _state.get('printerqueue/getQueue');
}

const add = (printjob) => {
    logger.info('printjob:add')
    let printerqueue = _state.get('printerqueue/getQueue');

    logger.info({
        type: 'printjob:add',
        printerqueue: printerqueue.length
    });
    // logger.info(printerqueue);

    printerqueue.push(printjob);

    _state.set('printerqueue/setQueue', printerqueue);
}

const remove = () => {
    console.log('remove printjob - start')
    let queue = _state.get('printerqueue/getQueue');


    logger.info({
        type: 'printjob:remove',
        printerqueue: queue.length
    });

    console.log('remove printjob - queue before', queue);
    queue.shift();

    console.log('remove printjob - queue after', queue);
    _state.set('printerqueue/setQueue', queue);
    console.log('remove printjob - end')
}

const clear = () => {
    logger.info({
        type: 'printjob:clear',
    });
    console.log('clear printjob');
    _state.set('printerqueue/setQueue', []);
}

export {
    get,
    clear,
    add,
    remove
}
