<template>
    {{currency}}
</template>

<script>

export default {
    date () {
        return {
            currency: null
        }
    },
    beforeMount () {
        switch(this.terminal.currency){
            case 'eur':
                this.currency = '€';
                break;
            case 'usd':
                this.currency = '$';
                break;
            case 'gbp':
                this.currency = '£';
                break;
            case 'chf':
                this.currency = '¥';
                break;
            default:
                this.currency = '€';
        }
    },
    computed: {
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
    }
}
</script>
